import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import forge from 'node-forge';
import { getPublicKey, generateSessionToken } from '../Routes/BaseUrl';

const AbhaExample = () => {

  const [aadhaar, setAadhaar] = useState('');
  const [txnId, setTxnId] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState('');

  // Generate session token
  // const generateSessionToken = async () => {
  //   try {
  //     const response = await axios.post(
  //       'https://dev.abdm.gov.in/api/hiecm/gateway/v3/sessions',
  //       {
  //         clientId: 'SBXID_008175', // Replace with your clientId
  //         clientSecret: '1fa1cd7f-a2bb-468b-b6c2-cc16d725cbdf', // Replace with your clientSecret
  //       }
  //     );
  //     return response.data.accessToken;
  //   } catch (error) {
  //     console.error('Error generating session token:', error);
  //     return null;
  //   }
  // };

  // Fetch public key for encryption
  // const getPublicKey = async () => {
  //   try {
  //     const response = await axios.get('https://abhasbx.abdm.gov.in/abha/api/v3/profile/public/certificate');
  //     return response.data.publicKey;
  //   } catch (error) {
  //     console.error('Error fetching public key:', error);
  //     return null;
  //   }
  // };


  // Encrypt Aadhaar using the public key
  const encryptData = (dataToEncrypt, publicKeyPem) => {
    try {
      const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
      const encryptedData = publicKey.encrypt(dataToEncrypt, 'RSA-OAEP', {
        md: forge.md.sha1.create(),
      });
      return forge.util.encode64(encryptedData);
    } catch (error) {
      console.error('Error encrypting data:', error);
      return null;
    }
  };

  // Send Aadhaar OTP request
  const sendAadhaarOtp = async (encryptedAadhaar) => {
    const authToken = await generateSessionToken();
    if (!authToken) {
      setError('Unable to generate session token.');
      return;
    }

    try {
      const response = await axios.post(
        'https://abhasbx.abdm.gov.in/abha/api/v3/enrollment/request/otp',
        {
          txnId: txnId || '', // Empty if new transaction, or set previously
          scope: ['abha-enrol'],
          loginHint: 'aadhaar',
          loginId: encryptedAadhaar,
          otpSystem: 'aadhaar',
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            REQUEST_ID: uuidv4(), // Unique UUID for tracking
            TIMESTAMP: new Date().toISOString(),
            'Content-Type': 'application/json',
          },
        });

      setTxnId(response.data.txnId);
      setOtpSent(true);
      console.log('OTP sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending Aadhaar OTP:', error);
      setError('Failed to send OTP. Please try again.');
    }
  }
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (aadhaar.length !== 12) {
      setError('Please enter a valid 12-digit Aadhaar number.');
      return;
    }

    const publicKey = await getPublicKey();
    if (!publicKey) {
      setError('Failed to fetch public key.');
      return;
    }

    const encryptedAadhaar = encryptData(aadhaar, publicKey);
    if (!encryptedAadhaar) {
      setError('Failed to encrypt Aadhaar number.');
      return;
    }

    setError('');
    await sendAadhaarOtp(encryptedAadhaar);
  };

  return (
    <div>
      <h1>ABHA Aadhaar OTP Registration</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter your Aadhaar"
          value={aadhaar}
          onChange={(e) => setAadhaar(e.target.value)}
          className="border border-black"
        />
        <button type="submit" className="border border-black ml-4">Send OTP</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {otpSent && <p style={{ color: 'green' }}>OTP sent successfully to Aadhaar linked mobile!</p>}
    </div>
  );
};

export default AbhaExample;