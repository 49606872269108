import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { BaseUrl, generateSessionToken } from "../../../Routes/BaseUrl"; // Adjust the import path as needed

const DoctorOtpVerify = () => {
  const [txnId, setTxnId] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { emailId, mobileNumber, fullName, password } = location.state || {};

  const handleTxnId = (e) => setTxnId(e.target.value);
  const handleMobileOtpChange = (e) => setMobileOtp(e.target.value);

  const handleVerify = async () => {
    const authToken = await generateSessionToken(); // Step 1: Generate session token
    if (!authToken) {
      return; // Handle error case
    }

    try {
      const response = await axios.post(
        "https://abhasbx.abdm.gov.in/abha/api/v3/enrollment/verify/otp",
        {
          txnId: txnId,       // Transaction ID received from the OTP request
          otp: mobileOtp           // OTP entered by the user
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Add session token here
            "Content-Type": "application/json",
          },
        }
      );

      console.log("OTP verified successfully:", response.data);
      Swal.fire("Success", "OTP Verified Successfully!", "success");
      navigate("/doctor/profile");
      return response.data; // Return the response data for further handling if needed
    } catch (error) {
      console.error("Error verifying OTP:", error);
      Swal.fire("Error", "Failed to Verify OTP. Please try again.", "error");
      return null;
    }
  };


  // const handleVerify = async () => {
  //   setLoading(true);

  //   if (!emailOtp || !mobileOtp) {
  //     setLoading(false);
  //     Swal.fire("Error", "Please enter both OTPs.", "error");
  //     return;
  //   }

  //   const payload = {
  //     emailId,
  //     emailOTP: emailOtp,
  //     mobileNumber,
  //     mobileOTP: mobileOtp,
  //     fullName,
  //     password,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${BaseUrl}/doctor/doctorVerify`,
  //       payload
  //     );
  //     if (response.status === 200) {
  //       Swal.fire("Success", "Verification successful!", "success");
  //       navigate("/doctor/profile");
  //     } else {
  //       Swal.fire(
  //         "Error",
  //         response.data.message || "Verification failed.",
  //         "error"
  //       );
  //     }
  //   } catch (error) {
  //     Swal.fire(
  //       "Error",
  //       error.response?.data?.message ||
  //         "Network error. Please try again later.",
  //       "error"
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <section>
      <div className="container border py-3">
        <div className="row justify-content-center">
          <form
            className="width-50 border form-doctor-right form-register"
            onSubmit={(e) => {
              e.preventDefault();
              handleVerify();
            }}
          >
            <h3>Two Step Verification</h3>
            <span className="mt-3">Verify Otp </span>
            {/* <p className="my-3">
              To verify your email, we sent you a code on your email address.
            </p> */}
            <div className="d-flex flex-column mb-3">
              <label>Enter Txn Id</label>
              <input
                type="text"
                placeholder="Enter Txn Id"
                value={txnId}
                onChange={handleTxnId}
                required
              />
            </div>
            {/* <span className="mt-3">Verify Mobile Number </span> */}
            <p className="my-3">
              To verify your number, we sent you an SMS on your phone.
            </p>
            <div className="d-flex flex-column mb-3">
              <label>Enter Mobile number OTP </label>
              <input
                type="text"
                placeholder="Enter OTP"
                value={mobileOtp}
                onChange={handleMobileOtpChange}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-white"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              ) : (
                "Verify"
              )}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default DoctorOtpVerify;
