import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function ChooseAcc() {

    const navigate = useNavigate();

    

    React.useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.setItem("myPath", window.location.pathname);
      }, []);
    return (
        <div className="App w-[100%]">
            <button onClick={()=>navigate(-1)} className=" ml-[3%] mt-[2%]">
                <img src={require('../Assets/image/left2.png')} className="h-[44px] w-[44px]" />
            </button>
            <div className="flex flex-col w-80% items-center">
                <h1 className=" text-black font-[Gilroy-SemiBold] text-[26px] w-[80%]">Choose a way to setup your account</h1>
                <p className=" w-[80%] font-[Gilroy-SemiBold] text-[18px] text-[#616161]">
                    You’ll use this method to set up your account. Choose an option that works best for you.
                </p>
            </div>
            <Types />
        </div>
    )
}



const Types = () => {
    return (
        <div className="flex flex-col md:flex-row pb-4 rounded-[5px] m-4 justify-between items-start mb-[5%]">
            <div className="col w-[full] md:w-[30%] md:ml-10 border-1 rounded-xl p-6 bg-[#F8FBFF]">
                <div className="flex flex-row items-end">
                    <h1 className="font-semiBold text-[45px] text-[#535353]" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h1><h6 className="text-xl text-[#FD7979] italic">Expert</h6>
                </div>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    For Health Professionals
                </p>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    A free practice & profile management tool for Doctors;
                </p>
                <div className="mb-4 mt-6">
                    <ul className="mb-3 p-0">
                        {[
                            {
                                image: require('../Assets/image/app1.png'),
                                description: 'Comprehensive Profile',
                            },
                            {
                                image: require('../Assets/image/app2.png'),
                                description: 'Online reach',
                            },
                            {
                                image: require('../Assets/image/app3.png'),
                                description: 'patient management',
                            },
                            {
                                image: require('../Assets/image/app4.png'),
                                description: 'Ai assistance(Dr Jii)',
                            },
                            {
                                image: require('../Assets/image/app5.png'),
                                description: ' Community support(Docare)',
                            },
                            {
                                image: require('../Assets/image/app6.png'),
                                description: 'Earning Growth',
                            },
                        ].map((item, index) => (
                            <li key={index} className="d-flex align-items-center gap-3 mb-3">
                                <img src={item.image} className="h-[24px] w-[24px]" />
                                <div className="flex flex-col w-full items-start">
                                    {item.description}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <button className="bg-[white] h-10 w-[90%] rounded-full mt-4 flex flex-row items-center justify-center ml-[2%] mb-[4%] border-1 border-[black]">
                    <span className="text-[16px] text-[black] font-[Gilroy-SemiBold] self-center">Create profile with HPR</span>
                    <img src={require('../Assets/image/rightBlack.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
            </div>
            <div className="col w-[full] md:w-[30%] md:ml-10 rounded-xl border-1 p-6 bg-[#F8FBFF]">
                <div className="flex flex-row items-end">
                    <h1 className="font-semiBold text-[45px] text-[#535353]" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h1><h6 className="text-xl text-[#12CDB7] italic">Alpha</h6>
                </div>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    For Health Facilities
                </p>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    A Clinic management software for <h6 className="text-[black] font-[Gilroy-SemiBold]">999₹/month;</h6>
                </p>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    Everything in Hobby, plus:
                </p>
                <div className="mb-4">
                    <ul className="mb-3 p-0">
                        {[
                            {
                                image: require('../Assets/image/alpha1.png'),
                                description: 'AI powered operations',
                            },
                            {
                                image: require('../Assets/image/alpha2.png'),
                                description: 'Facility marketing tools',
                            },
                            {
                                image: require('../Assets/image/alpha3.png'),
                                description: 'Smart Calling system',
                            },
                            {
                                image: require('../Assets/image/alpha4.png'),
                                description: 'Patient relationship',
                            },
                            {
                                image: require('../Assets/image/alpha5.png'),
                                description: 'Secure Data',
                            },
                            {
                                image: require('../Assets/image/alpha6.png'),
                                description: 'Facility Linkage',
                            },
                            {
                                image: require('../Assets/image/alpha7.png'),
                                description: 'More Profit & Growth',
                            },
                        ].map((item, index) => (
                            <li key={index} className="d-flex align-items-center gap-3 mb-3">
                                <img src={item.image} className="h-[24px] w-[24px]" />
                                <div className="flex flex-col w-full items-start">
                                    {item.description}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <button className="bg-[#0097DB] h-10 w-[90%] rounded-full mt-4 flex flex-row items-center justify-center ml-[2%] mb-[4%] border-[black]">
                    <span className="text-[16px] text-[white] font-[Gilroy-SemiBold] self-center">Start a free trial</span>
                    <img src={require('../Assets/image/rightWhite.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
            </div>
            <div className="col w-[full] md:w-[30%] md:ml-10 rounded-xl border-1 p-6 bg-[#F8FBFF]">
                <div className="flex flex-row items-end">
                    <h1 className="font-semiBold text-[45px] text-[#535353]" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h1><h6 className="text-xl text-[#12CDB7] italic">Alpha</h6><h6 className="text-[18px] text-[#F39090] ml-[2%]"> Pro</h6>
                </div>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    For Hospitals
                </p>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    A Hospital management system;
                </p>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    Everything in Pro, plus:
                </p>
                <div className="mb-4">
                    <ul className="mb-3 p-0">
                        {[
                            {
                                image: require('../Assets/image/pro1.png'),
                                description: 'IPD management',
                            },
                            {
                                image: require('../Assets/image/pro2.png'),
                                description: 'Professionals management',
                            },
                            {
                                image: require('../Assets/image/pro3.png'),
                                description: 'Stores management',
                            },
                            {
                                image: require('../Assets/image/pro4.png'),
                                description: 'Linkage System',
                            },
                            {
                                image: require('../Assets/image/pro5.png'),
                                description: 'Staff management',
                            },
                            {
                                image: require('../Assets/image/pro6.png'),
                                description: 'Easy Reports creation',
                            },
                            {
                                image: require('../Assets/image/pro7.png'),
                                description: 'Easy payments & claims',
                            },
                        ].map((item, index) => (
                            <li key={index} className="d-flex align-items-center gap-3 mb-3">
                                <img src={item.image} className="h-[24px] w-[24px]" />
                                <div className="flex flex-col w-full items-start">
                                    {item.description}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="flex flex-row items-center">
                <button className="bg-[black] h-10 w-[50%] rounded-full mt-4 flex flex-row items-center justify-center ml-[2%] mb-[5%] border-1 border-[black]">
                    <span className="text-[16px] text-[white] font-[Gilroy-SemiBold] self-center">Contact Sales</span>
                    <img src={require('../Assets/image/rightWhite.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
                <button className="bg-[white] h-10 w-[50%] rounded-full flex flex-row items-center justify-center ml-[2%] border-1 border-[black]">
                    <span className="text-[16px] text-[black] font-[Gilroy-SemiBold] self-center">Request Trial</span>
                    <img src={require('../Assets/image/rightBlack.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
                </div>
            </div>
        </div>
    )
}