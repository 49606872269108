import React from "react";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";

export default function DownloadApp() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.setItem("myPath", window.location.pathname);
      }, []);
    return (
        <div className="App w-[100%]">
            <ExpertNav />
            <Header />
            <Types />
            <Footer/>
        </div>
    )
}


const Header = () => {
    return (
        <div className="flex flex-col items-center w-full mb-10 mt-[5%]">
            <h1 className="text-center text-black font-[Gilroy-SemiBold] text-[26px]">Download Apps For Free</h1>
            <p className=" text-center w-[80%] font-[Gilroy-SemiBold] text-[18px] text-[#616161] mt-2">
                Get avijo app on Mobile or Desktop
            </p>
            {/* <div className="flex flex-row">
                <button className="bg-[#0097DB] h-10 w-[130px] rounded-full mt-4 flex flex-row items-center justify-center">
                    <span className="text-sm text-[white]">Start now</span>
                    <img src={require('../Assets/image/file.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
                <button className="bg-[white] h-10 w-[200px] rounded-full mt-4 flex flex-row items-center justify-center ml-4 border-1 border-[black]">
                    <span className="text-[16px] text-[black] font-[Gilroy-SemiBold] self-center">Download the app</span>
                    <img src={require('../Assets/image/right2.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
            </div> */}
        </div>
    )
}

const Types = () => {
    return (
        <div className="flex flex-col md:flex-row pb-4 rounded-[5px] m-4 justify-between items-start mb-[5%]">
            <div className="col w-[full] md:w-1/2 md:ml-10 border-1 p-6">
                <h3 className="text-[#0097DB] text-[40px]" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h3>
                <h3 className="text-[black] text-[24px] mt-10" style={{ fontFamily: 'Gilroy-SemiBold' }}>Your Health Guardian</h3>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    It helps the users in locating the best specialist around them, also can order medicine, find a hospital and Lab, etc.
                </p>
                <div className="mb-4 mt-6">
                    <ul className="mb-3 p-0">
                        {[
                            {
                                image: require('../Assets/image/app1.png'),
                                description: 'Find nearby doctors, hospitals. & Lab w/map.',
                            },
                            {
                                image: require('../Assets/image/app2.png'),
                                description: 'Book appointments, video consultation with Doctor.',
                            },
                            {
                                image: require('../Assets/image/app3.png'),
                                description: 'Multi-vendor medicine and health product ordering.',
                            },
                            {
                                image: require('../Assets/image/app4.png'),
                                description: 'Book appointments home sample collection for Lab test.',
                            },
                            {
                                image: require('../Assets/image/app5.png'),
                                description: ' Health Emergency services',
                            },
                            {
                                image: require('../Assets/image/app5.png'),
                                description: 'Dr. Jii (AI Assistance)',
                            },
                            {
                                image: require('../Assets/image/app6.png'),
                                description: 'Health Tracking ',
                            },
                        ].map((item, index) => (
                            <li key={index} className="d-flex align-items-center gap-3 mb-8">
                                <img src={item.image} className="h-[24px] w-[24px]" />
                                <div className="flex flex-col w-full items-start">
                                    {item.description}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <button className="bg-[white] h-10 w-[240px] rounded-full mt-4 flex flex-row items-center justify-center ml-[2%] mb-[4%] border-1 border-[black]">
                    <span className="text-[16px] text-[black] font-[Gilroy-SemiBold] self-center">Download On App Store</span>
                    <img src={require('../Assets/image/right2.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
                <button className="bg-[white] h-10 w-[270px] rounded-full flex flex-row items-center justify-center ml-[2%] mb-[10%] border-1 border-[black]">
                    <span className="text-[16px] text-[black] font-[Gilroy-SemiBold] self-center">Download On Google Play</span>
                    <img src={require('../Assets/image/right2.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
            </div>
            <div className="col w-[full] md:w-1/2 md:ml-10 border-1 p-6">
                <div className="flex flex-row items-end">
                    <h1 className="font-semiBold text-[45px] text-[#535353]" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h1><h6 className="text-xl text-[#FD7979] italic">Expert</h6>
                </div>
                <h3 className="text-[black] text-[24px] mt-10" style={{ fontFamily: 'Gilroy-SemiBold' }}>For Professionals</h3>
                <p className="font-[Gilroy-SemiBold] w-[80%] text-[16px] text-[#616161] mt-2">
                    A practice & profile management tool for Doctors
                </p>
                <div className="mb-4">
                    <ul className="mb-3 p-0">
                        {[
                            {
                                image: require('../Assets/image/app1.png'),
                                description: 'Comprehensive Profile',
                            },
                            {
                                image: require('../Assets/image/app2.png'),
                                description: 'Online reach',
                            },
                            {
                                image: require('../Assets/image/app3.png'),
                                description: 'patient management',
                            },
                            {
                                image: require('../Assets/image/app4.png'),
                                description: 'Ai assistance(Dr Jii)',
                            },
                            {
                                image: require('../Assets/image/app5.png'),
                                description: 'Community support(Docare)',
                            },
                            {
                                image: require('../Assets/image/app6.png'),
                                description: 'Earning Growth',
                            },
                            {
                                image: require('../Assets/image/app7.png'),
                                description: 'Earning Growth',
                            },
                        ].map((item, index) => (
                            <li key={index} className="d-flex align-items-center gap-3 mb-8">
                                <img src={item.image} className="h-[24px] w-[24px]" />
                                <div className="flex flex-col w-full items-start">
                                    {item.description}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <button className="bg-[white] h-10 w-[240px] rounded-full mt-4 flex flex-row items-center justify-center ml-[2%] mb-[4%] border-1 border-[black]">
                    <span className="text-[16px] text-[black] font-[Gilroy-SemiBold] self-center">Download On App Store</span>
                    <img src={require('../Assets/image/right2.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
                <button className="bg-[white] h-10 w-[270px] rounded-full flex flex-row items-center justify-center ml-[2%] mb-[10%] border-1 border-[black]">
                    <span className="text-[16px] text-[black] font-[Gilroy-SemiBold] self-center">Download On Google Play</span>
                    <img src={require('../Assets/image/right2.png')} className="h-[20px] w-[20px] ml-2" />
                </button>
            </div>
        </div>
    )
}