import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import NotFound from "./Pages/NotFound";
import Login from "./Pages/Users/Login";
import UserHeader from "./components/Users/header/Header";
import "./styles/index.css";
import CreateAccount from "./Pages/Users/CreateAccount";
import HomeNavbar from "./components/Users/navbar/HomeNav";
import Home from "./Pages/Users/Home";
import UserLoginOtp from "./components/Users/Ui/UserLoginOtp";
import DoctorLogin from "./Pages/Doctor/Login";
import ProfileEdit from "./Pages/Doctor/ProfileEdit";
import Profile from "./Pages/Doctor/Profile";
import OtpVerify from "./Pages/Doctor/OtpVerify";
import ProfileNavbar from "./components/Doctors/DoctorsProfile/ProfileNavbar";
import ProfileEditNav from "./components/Doctors/DoctorEditProfile/ProfileEditNav";
import PharmacyLogin from "./Pages/Pharmacy/Login";
import PharmacyProfileEdit from "./Pages/Pharmacy/ProfileEdit";
import PharmacyProfile from "./Pages/Pharmacy/Profile";
import PharmacyOtpVerify from "./Pages/Pharmacy/OtpVerify";
import PharmacyProfileNavbar from "./components/Pharmacy/PharmacyProfile/ProfileNavbar";
import PharmacyProfileEditNav from "./components/Pharmacy/PharmacyEditProfile/ProfileEditNav";
import LabLogin from "./Pages/Lab/Login";
import LabProfileEdit from "./Pages/Lab/ProfileEdit";
import LabProfile from "./Pages/Lab/Profile";
import LabOtpVerify from "./Pages/Lab/OtpVerify";
import LabProfileNavbar from "./components/Lab/LabProfile/ProfileNavbar";
import LabProfileEditNav from "./components/Lab/LabEditProfile/ProfileEditNav";
import MainHome from "./Pages/Home";
import HomeNav from "./components/Users/navbar/HomeNav";
import TermsAndConditions from "./Pages/Legals/TermsAndConditions";
import PrivacyPolicy from "./Pages/Legals/PrivacyPolicy";
import FeesAndPaymentsPolicy from "./Pages/Legals/FeesAndPaymentsPolicy";
import ShippingAndDeliveryPolicy from "./Pages/Legals/ShippingAndDeliveryPolicy";
import ReturnRefundAndCancellationPolicy from "./Pages/Legals/Return-Refund-And-Cancellation-Policy";
import EditorialPolicy from "./Pages/Legals/EditorialPolicy";
import Verfied from "./Pages/VerfiedPage/Verfied";
import HPPLogin from "./Pages/HPP/Login";
import HPPProfileEdit from "./Pages/HPP/ProfileEdit";
import HPPProfile from "./Pages/HPP/Profile";
import HPPOtpVerify from "./Pages/HPP/OtpVerify";
import HPPProfileNavbar from "./components/HPP/HPPProfile/ProfileNavbar";
import HPPProfileEditNav from "./components/HPP/HPPEditProfile/ProfileEditNav";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import ABDM from "./Pages/ABDM";
import Safety from "./Pages/Safety";
import ContactNav from "./components/Navbar/ContactNav";
import ForgetPassword from "./Pages/Pharmacy/ForgetPassword";
import ResetPassword from "./Pages/Pharmacy/ResetPassword";
import AvijoAlpha from "./Pages/AvijoAlpha";
import AvijoExpert from "./Pages/AvijoExpert";
import HppHome from "./Pages/HppHome";
import DoCare from "./Pages/DoCare";
import HealthAssistant from "./Pages/HealthAssistant";
import DownloadApp from "./Pages/DownloadApp";
import ChooseAcc from "./Pages/ChooseAcc";
import GenerateId from "./Pages/GenerateId";
import AbdmLogin from "./Pages/AbdmLogin";
import AbdmOtp from "./Pages/AbdmOtp";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("myPath", location.pathname);
  }, [location.pathname]);

  const currentPath = location.pathname;

  const skdfjk = [
    "/doctor/profile",
    "/doctor/edit-profile",
    "/hpp/profile",
    "/hpp/edit-profile",
    "/lab/edit-profile",
    "/about",
    "/ABDM",
    "/safety",
    "/pharmacy/edit-profile",
    "/user/home",
  ];

  const pathsToHideNavbar = [
    "/doctor/login",
    "/doctor/otp-verify",
    "/hpp/login",
    "/hpp/otp-verify",
    "/pharmacy/login",
    "/user/login",
    "/user/otp-verify",
    "/pharmacy/reset-password/",
    "/user/create-account",
    "/pharmacy/otp-verify",
    "/pharmacy/forget-password",
    "/lab/login",
    "/lab/otp-verify",
    "/Privacy-policy",
    "/Shipping-and-Delivery-policy",
    "/Editorial-Policy",
    "/Terms-and-conditions",
    "/Return-Refund-And-Cancellation-Policy",
    "/Fees-and-Payments-policy",
    "/Verfied",
    "/contact",
    "/",
  ];

  const isNavbarVisible = () => {
    for (let path of pathsToHideNavbar) {
      if (currentPath.startsWith(path)) {
        return false;
      }
    }
    return true;
  };

  const showProfileNavbar = currentPath === "/doctor/profile";
  const showProfileEditNavbar = currentPath === "/doctor/edit-profile";
  const showHPPProfileNavbar = currentPath === "/hpp/profile";
  const showHPPProfileEditNavbar = currentPath === "/hpp/edit-profile";
  const showLabProfileEditNavbar = currentPath === "/lab/edit-profile";
  const showContactNavbar = currentPath === "/about" || currentPath === "/ABDM";
  const showSafetyNavbar = currentPath === "/safety";
  const showUserHome = currentPath === "/user/home";
  const showPharmacyProfileEditNavbar =
    currentPath === "/pharmacy/edit-profile";
  const showHeader = currentPath.startsWith("/user") && !showUserHome;

  const showNavbar = isNavbarVisible();

  return (
    <>
      {showHPPProfileNavbar && <HPPProfileNavbar />}
      {showContactNavbar && <ContactNav />}
      {showSafetyNavbar && <ContactNav />}
      {showProfileNavbar && <ProfileNavbar />}
      {showHeader && <UserHeader />}
      {showUserHome && <HomeNav />}
      {showNavbar && <HomeNavbar />}
      {showProfileEditNavbar && <ProfileEditNav />}
      {showHPPProfileEditNavbar && <HPPProfileEditNav />}
      {showPharmacyProfileEditNavbar && <PharmacyProfileEditNav />}
      {showLabProfileEditNavbar && <LabProfileEditNav />}
      <Routes>
        {/* Home Route */}
        <Route path="/" element={<MainHome />} />
        {/* User Route */}
        <Route path="/user/home" element={<Home />} />
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/otp-verify" element={<UserLoginOtp />} />
        <Route path="/user/create-account" element={<CreateAccount />} />
        {/* Doctor Route */}
        <Route path="/doctor/login" element={<DoctorLogin />} />
        <Route path="/doctor/generateid" element={<AbdmLogin />} />
        <Route path="/doctor/profile" element={<Profile />} />
        <Route path="/doctor/edit-profile" element={<ProfileEdit />} />
        <Route path="/doctor/otp-verify" element={<OtpVerify />} />
        {/* pharmacy Route */}
        <Route path="/pharmacy/login" element={<PharmacyLogin />} />
        <Route path="/pharmacy/profile" element={<PharmacyProfile />} />
        <Route
          path="/pharmacy/edit-profile"
          element={<PharmacyProfileEdit />}
        />
        <Route path="/pharmacy/forget-password" element={<ForgetPassword />} />
        <Route
          path="/pharmacy/reset-password/:id"
          element={<ResetPassword />}
        />
        <Route path="/pharmacy/otp-verify" element={<PharmacyOtpVerify />} />
        {/* lab Route */}
        <Route path="/lab/login" element={<LabLogin />} />
        <Route path="/lab/profile" element={<LabProfile />} />
        <Route path="/lab/edit-profile" element={<LabProfileEdit />} />
        <Route path="/lab/otp-verify" element={<LabOtpVerify />} />
        {/* legals */}
        <Route path="/Terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/Privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/Fees-and-Payments-policy"
          element={<FeesAndPaymentsPolicy />}
        />
        <Route
          path="/Shipping-and-Delivery-policy"
          element={<ShippingAndDeliveryPolicy />}
        />
        <Route
          path="/Return-Refund-And-Cancellation-Policy"
          element={<ReturnRefundAndCancellationPolicy />}
        />
        <Route path="/Editorial-Policy" element={<EditorialPolicy />} />
        {/* Verifed */}
        <Route path="/Verfied" element={<Verfied />} />
        {/* HPP Route */}
        <Route path="/hpp/login" element={<HPPLogin />} />
        <Route path="/hpp/profile" element={<HPPProfile />} />
        <Route path="/hpp/edit-profile" element={<HPPProfileEdit />} />
        <Route path="/hpp/otp-verify" element={<HPPOtpVerify />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/ABDM" element={<ABDM />} />
        <Route path="/Avijo/Alpha" element={<AvijoAlpha />} />
        <Route path="/Avijo/Expert" element={<AvijoExpert />} />
        <Route path="/hpp/home" element={<HppHome />} />
        <Route path="/Docare" element={<DoCare />} />
        <Route path="/HealthAssistant" element={<HealthAssistant />} />
        <Route path="/DownloadApp" element={<DownloadApp />} />
        <Route path="/ChooseAccount" element={<ChooseAcc />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
