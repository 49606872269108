import axios from "axios";
import CryptoJS from "crypto-js";
import forge from "node-forge";
// export const BaseUrl = "http://18.209.67.91:6060";
// Mohit AWS Url
// export const BaseUrl = "http://13.60.63.167:6060";
// Ajay AWS Url
export const BaseUrl =
  "https://avijobackendtest-production-6295.up.railway.app";

  export const abdUrl = "https://abhasbx.abdm.gov.in/abha/api/v3/enrollment";


  // export const encryptAadhaar = (aadhaarNumber, secretKey) => {
  //   return CryptoJS.AES.encrypt(aadhaarNumber, secretKey).toString();
  // };

  export const importPublicKey=async(pem)=> {
    // Remove header, footer, and newline characters from PEM
    const pemContents = pem
        .replace(/-----BEGIN PUBLIC KEY-----/, "")
        .replace(/-----END PUBLIC KEY-----/, "")
        .replace(/\s+/g, "");

    // Decode base64 to binary DER
    const binaryDer = Uint8Array.from(atob(pemContents), (c) => c.charCodeAt(0));
    
    // Import key using the Web Crypto API
    return crypto.subtle.importKey(
        "spki", // The format of the key
        binaryDer.buffer,
        {
            name: "RSA-OAEP",
            hash: "SHA-256",
        },
        true, // Whether the key is extractable
        ["encrypt"] // Key usage
    );
}


  export   const generateSessionToken = async () => {
    try {
      const response = await axios.post(
        "https://dev.abdm.gov.in/api/hiecm/gateway/v3/sessions",
        {
          clientId: "SBXID_008175", // Replace with actual clientId
          clientSecret: "1fa1cd7f-a2bb-468b-b6c2-cc16d725cbdf", // Replace with actual clientSecret
          grantType: "client_credentials",
        }
      );
      return response.data.accessToken; // Use accessToken in the response
    } catch (error) {
      console.error("Error generating session token:", error);
      return null;
    }
  };


  export  const getPublicKey = async () => {
    try {
      const response = await axios.get(
        "https://healthidsbx.abdm.gov.in/api/v1/auth/cert"
      );
      return response.data; // Return the full public key
    } catch (error) {
      console.error("Error fetching public key:", error);
      return null;
    }
  };

  export const encryptAadhaar=async(aadhaarId, publicKey)=> {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(aadhaarId);

    const encrypted = await crypto.subtle.encrypt(
        {
            name: "RSA-OAEP",
        },
        publicKey,
        encodedData
    );

    return btoa(String.fromCharCode(...new Uint8Array(encrypted))); // Convert to Base64 for transmission
}

  export const encryptData = (dataToEncrypt, publicKeyPem) => {
    try {
      // Remove the 'BEGIN' and 'END' lines from the PEM key
      const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

      // Encrypt the data using the public key
      const encryptedData = publicKey.encrypt(dataToEncrypt, "RSA-OAEP", {
        md: forge.md.sha256.create(),
      });

      // Convert encrypted data to base64 format
      const encryptedBase64 = forge.util.encode64(encryptedData);
      return encryptedBase64;
    } catch (error) {
      console.error("Error encrypting data:", error);
      return null;
    }
  };


// Render Url
// export const BaseUrl = "https://avijo-1.onrender.com";
